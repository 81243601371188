@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;900&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Raleway;
  src: url(./assets/fonts/Raleway-Regular.ttf) format("truetype");
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex.column {
  flex-direction: column;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-start {
  align-items: flex-start;
}

.flex.align-end {
  align-items: flex-end;
}

.flex.align-center {
  align-items: center;
}

.flex.content-spacebetween {
  justify-content: space-between;
}

.flex.content-spacearound {
  justify-content: space-around;
}

.flex.content-end {
  justify-content: flex-end;
}

.flex.content-start {
  justify-content: flex-start;
}

.flex.content-center {
  justify-content: center;
}

.flex1 {
  flex: 1;
}

.mb-10 {
  margin-bottom: 10px;
}

.gap {
  column-gap: 15px;
}

.tabsSection {}

.tabsSection .tabs {}

.tabsSection .tabs .tabsItem {
  text-align: center;
  cursor: pointer;
  padding: 20px 0px;
  background: white;
  font-size: 18px;
  color: #121217;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.tabsSection .tabs .tabsItem.activeTab {
  background: #1a1717;
  color: white;
}

.tabsSection .activeTab h5 {
  font-size: 21px;
}

.tabsSection {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 4px;
}

.btn_black {
  padding-left: 20px;
  padding-right: 20px;
  background: #1a1717;
  border: none;
  border-radius: 6px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  outline: none;
}

.btn_black:hover {
  background: #1a1717;
}

.btn_disabled {
  pointer-events: none;
  opacity: 0.7;
}

.btn_width {
  width: 130px;
}

.max-200 {
  max-width: 200px;
}

.max-250 {
  max-width: 250px;
}

.column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.selectDropdown {
  width: 200px !important;
  height: 34px;
}

.searchContainer {
  margin-left: -20px;
}

.searchContainer .form-group {
  margin-bottom: 20px;
  margin-left: 20px;
}

.searchContainer .form-group .form-control {
  border: 1px solid gray;
  border-radius: 0.3rem;
  width: 100%;
  padding: 5px 5px;
}

.leftContainer .form-group label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 4px;
}

.rightContainer .form-group label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 4px;
}

.filter-container {
  margin: 0.5rem 0;
}

.dispacth-history {
  margin-left: 1rem;
}

.filter-container .filter {
  border: 1px solid gray;
  border-radius: 0.3rem;
  margin-right: 1rem;
}

.advanceFilter {
  flex: 1 1;
  margin-left: 13px;
  font-size: 16px;
  text-decoration-line: underline;
  color: #121214;
  font-weight: 500;
  cursor: pointer;
}

.form-group {
  margin-bottom: 20px;
  margin-left: 20px;
}

.form-group .form-control {
  border: 1px solid gray;
  border-radius: 0.3rem;
  width: 100%;
  padding: 5px 5px;
}

.form-group label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 4px;
}

.ant-table-row:hover {
  background: white;
}

thead[class*="ant-table-thead"] th {
  color: #121217 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 10px !important;
}

.col-header {
  background-color: #fff !important;
}

.cls-1 {
  fill: #fff !important;
}

.cls-2 {
  fill: #000 !important;
}

.forbiddenSection {
  max-width: 500px;
  margin: 0px auto;
}

.forbiddenSection .forbiddenWrap {
  padding: 40px 0px;
  text-align: center;
}

.forbiddenSection .forbiddenWrap .forbiddenContent {
  margin-bottom: 30px;
}

.forbiddenSection .forbiddenWrap .forbiddenContent img {
  width: 570px;
}

.forbiddenSection .forbiddenWrap .content_section {
  margin-bottom: 30px;
}

.forbiddenSection .forbiddenWrap .content_section p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #2f63ff;
  margin-bottom: 18px;
}

.forbiddenSection .forbiddenWrap .content_section span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #727272;
}

.forbiddenSection .forbiddenWrap .btn_section {}

.forbiddenSection .forbiddenWrap .btn_section .btn_black {
  width: 227px;
  background: #2f63ff;
  border-radius: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ffffff;
  height: 57px;
}

.invoice-section {
  padding: 30px 40px;
}

.invoice-section .invoice-btn-section {
  margin-bottom: 30px;
}

.invoice-section .left-section {
  border-right: 1px solid #d1cece;
  padding-right: 10px;
}

.invoice-section .invoice-btn-section .btn_green {
  background: #0a7808;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  outline: none;
  cursor: pointer;
  margin-right: 20px;
}

.invoice-section .invoice-btn-section .btn_red {
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  outline: none;
  background: #c51111;
  border-radius: 4px;
  cursor: pointer;
}

.invoice-section .header_heading_btn {
  margin-bottom: 30px;
}

.invoice-section .header_heading_btn h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.ant-input:hover {
  border-color: #787878 !important;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.invoice-section .right-section {
  max-width: 500px;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.ml-30 {
  margin-left: 30px;
}

.max-120 {
  max-width: 120px;
}

.label_box {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
}

.label_box:hover {
  cursor: not-allowed;
}

.antd-table-custom-class thead th,
.antd-table-custom-class tbody td {
  white-space: nowrap;
  word-break: break-word;
  word-break: break-all;
}

.position_bottom_0 {
  position: fixed !important;
  bottom: 30px;
}

.position_bottom_0 .leftIconShipper {
  height: 15px !important;
  width: 15px !important;
}

.agent_right_box {
  row-gap: 20px;
  column-gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.activeDispatch {
  padding: 20px 5px !important;
  border: none !important;
}

.view_dispatch_section {
  background: white;
  margin-left: 20px;
  margin-right: 29px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.dispatch_fleet_payment_section .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.customLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99999;
}

.inventory_wrap {
  background-color: #ffffff;
}

.inventory_wrap .inventory_wrap_box {
  border-radius: 10px;
  min-height: 135px;
  padding: 15px 30px;
  margin: 5px 5px 10px;
  background: #dddddd14;
  border: 1px solid #0000001c;
}

.mb-20 {
  margin-bottom: 20px;
}

.fo_payment_setting .ant-form-item-label {
  text-align: left;
  font-weight: 600;
}

.upload_excel_btn {
  display: flex;
  justify-content: end;
  align-items: end;
  flex: 1;
  width: 100%;
}

.upload_excel_btn .ant-upload-list {
  display: none;
}

.finance_ag_logo {}

.cursor-pointer {
  cursor: pointer;
}

.finance_tab {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  max-width: 100px;
  cursor: pointer;
  max-width: 100px;
  text-align: center;
}

.active_headerTab {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  background: #000000;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 10px;
  max-width: 100px;
}

.isFinancePadding {
  padding: 0px 0px 0px !important;
}

.shipper_section {
  background: #ffffff;
}

.shipper_section .shipper_section_sidebar {
  padding: 10px 30px;
  max-width: 320px;
  border-right: 1px solid #d2d2d2;
  overflow: hidden;
}

.shipper_heading {
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 18px;
}

.input_box {
  position: fixed;
}

.input_box .form-control {
  background: #d9d9d9;
  border-radius: 11px;
  border: none;
  width: 220px;
}

.input_box.form-control.button {
  color: #fff !important;
  border-color: #1890ff !important;
  background: #1890ff !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%) !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%) !important;
}

.shipper_sidebar_list {
  margin-top: 150px;
  margin-bottom: 30px;
}

.shipper_sidebar_list .shipper_sidebar_list_inner {}

.shipper_sidebar_list .shipper_sidebar_list_inner .shipper_list_name_wrap {
  margin-bottom: 10px;
  border-radius: 11px;
  border: none;
  width: 220px;
}

.shipper_sidebar_list .shipper_sidebar_list_inner .shipper_list_name_wrap.selected {
  background: #121217;
  color: #ffffff;
}

.shipper_sidebar_list .shipper_sidebar_list_inner .shipper_list_name_wrap .shipper_list_name {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  cursor: pointer;
  padding: 5px;
}

.right_section {
  padding: 0px 30px;
}

.right_section .go_account {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 40px;
}

.right_section .go_account h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.right_section .go_account .arrowicon {
  background: #000;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
}

.month_name_finance {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}

.checkMarklist {
  margin-left: 10px;
  color: green !important;
}

.payment_details_section {
  background: #ffffff;
  padding: 20px 0px;
}

.payment_details_section h5 {
  font-weight: 700;
}

.payment_details_section .tab_section_payment {
  border-bottom: 1px solid #d2d2d2;
  padding-bottom: 15px;
}

.payment_details_section .tab_name {
  cursor: pointer;
  background: white;
  font-size: 18px;
  color: #121217;
}

.payment_details_section .left_bar {
  border-right: 1px solid #d2d2d2;
  padding-right: 30px;
  padding-top: 40px;
  margin-right: 30px;
}

.left_bar_common {
  border-right: 1px solid #d2d2d2;
  padding-right: 30px;
  margin-right: 30px;
}

.payment_details_section .left_text {
  border: 0px;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
  text-transform: capitalize;
  max-width: 270px;
}

.payment_details_section .right_text {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  max-width: 240px;
}

.table_b_h {
  border: 1px solid #1a1717;
}

.table_b_h thead {
  background: #1a1717;
}

.table_b_h tbody {}

.table_b_h th {
  color: #fff;
  padding: 5px 10px;
}

.table_b_h td {
  border: 1px solid #1a1717;
  color: #1a1717;
  padding: 5px 10px;
}

.go_back_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin: 15px 30px 10px;
}

.backicon_finance {
  /*  position: absolute;
  left: 30px;
  top: 16px; */
}

.shipperNameBackIcon {
  /* position: absolute;
  right: 30px;
  top: 16px; */
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-transform: capitalize;
}

.go_back {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.payment_tracking {}

.payment_tracking .payment_tracking_inner {}

.payment_tracking .payment_tracking_inner h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}

.payment_tracking .payment_tracking_inner h3 {
  text-align: center;
  padding: 20px;
  font-weight: 600;
}

.fo_payment_tracking_form .ant-form-item-label {
  text-align: left;
}

.fo_payment_tracking_form .ant-form-item {
  margin-bottom: 20px !important;
}

.headingShipperDetails {
  font-weight: 700;
  font-size: 20px;
  margin: 30px 0px 14px;
  line-height: 24px;
}

.flexgrow1 {
  flex-basis: 35%;
}

.flexgrow2 {
  flex-basis: 60%;
}

.shipper_payment_details_section {
  background: #ffffff;
  color: #000000;
}

.shipper_payment_details_section .shipper_details_upper {
  width: 100%;
}

.shipper_payment_details_section .shipper_details_upper h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.shipper_payment_details_section .shipper_details_upper p {
  font-size: 20px;
  line-height: 24px;
}

.editPaymentDetailsButton {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editPaymentDetailsButton button {
  background: #d9d9d9;
  border-radius: 11px;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding: 6px 30px;
  text-align: center;
  cursor: pointer;
}

.bg-grey {
  background: #d9d9d9 !important;
}

.react-pdf__Page__canvas {
  margin-left: auto;
  margin-right: auto;
}

.invoiceDownloadBtn {
  height: auto !important;
  line-height: 1 !important;
}

.date_filter_finance {
  margin-bottom: 30px !important;
}

.date_filter_finance_margin .have_margin_bottom {
  margin-top: 10px !important;
}

.multiple_finance.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: inherit;
  border: rgba(0, 0, 0, 0.85) !important;
  border-radius: unset !important;
}

.multiple_finance.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.multiple_finance.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.85) !important;
  border-right-width: unset !important;
}

.multiple_finance.ant-select-focus:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid rgba(0, 0, 0, 0.85) !important;
  border-right-width: unset !important;
}

.multiple_finance .ant-select-selection-placeholder {
  color: #a0a0a0 !important;
}

.f_setting_box {
  display: flex;
  margin: 20px;
  gap: 20px;
}

.d-block {
  display: block;
}

.card {
  width: calc(100% / 3 - 10px);
}

.card .left_text {
  padding-bottom: 12px;
}

.card .right_text {
  background: #f0efef;
  border-radius: 11px;
  padding: 5px 15px;
  width: 100%;
}

.date_edit_picker .ant-picker-input {
  cursor: pointer;
}

.date_edit_picker .ant-picker-input input {
  display: none;
}

.date_edit_picker .ant-picker-input .ant-picker-suffix {
  color: #2f63ff;
}

.shipper_account_setting {
  max-width: 420px;
  border-right: 1px solid #ddd;
  padding-right: 50px;
}

.bg-white {
  background: #ffffff;
}

.sop-section {
  max-width: 1024px;
  margin: 0px auto;
  padding: 40px;
  min-height: calc(100vh - 200px);
  overflow: scroll;
}

.sop-heading {
  font-size: 40px;
  margin: 0px 0px 40px;
}

.sop-important {
  color: #c64643;
  font-size: 25px;
  font-weight: 700;
}

.sop-mb-40 {
  margin-bottom: 40px;
}

.sop-advancepercentage {
  margin: 0px 0px 15px;
  font-size: 18px;
}

.sop-table {
  width: 100%;
  border-collapse: collapse;
}

.sop-table-td {
  border: 1px solid #ddd;
  padding: 7px;
  font-size: 14px;
}

.sop-ol {
  padding: 10px 0px 0px 20px;
}

.sop-li {
  padding-bottom: 5px;
}

.sop-ul {
  list-style: lower-alpha;
  padding: 5px 0px 0px 20px;
}

.profile_details {
  background: white;
}

.trackform .ant-form-item-control-input-content {
  display: block;
}

.trucks_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 60px;
}

.trucks_box .trucks-wrap {
  width: calc(100% / 2 - 10px);
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 10px;
}

.holiday_calendar .rc-h-48 {
  height: auto;
  min-height: 5.313rem;
}

.holiday_calendar .rc-h-48 .rc-flex {
  display: block;
}

.holiday_calendar .rc-h-48 .rc-font-bold {
  color: #000;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.holiday_calendar .rc-h-48 .rc-font-medium {
  color: #0e9f6e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
}

.holiday_calendar .rc-h-48 .rc-text-gray-500 {
  display: none;
}

.profile_underline {
  border-bottom: 1px solid black;
  /* Or your preferred color */
  display: inline-block;
  /* Allows the div to size according to its contents */
  width: 100%;
  /* Ensures the div spans the full width */
}

.selectProfileDropdown {
  width: 150px !important;
  height: 25px;
}

.inputbold {
  font-weight: 600;
}

.inputFullBold {
  font-weight: 600;
}

.inputFullBold input {
  font-weight: 600;
}