* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-form-item-control-input-content .invoiceFormControl {
  margin-left: 10px;
}

.ant-checkbox+span {
  user-select: none;
}

button {
  cursor: pointer;
}

.viewMoreText {
  font-weight: 700;
  color: #4214e7;
  cursor: pointer;
  font-size: 14px;
  text-align: right;
  display: block;
  position: relative;
  z-index: 999;
}

._border_bottom {
  border-bottom: 1px solid #000;
}